import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _351d4903 = () => interopDefault(import('../pages/banner/index.vue' /* webpackChunkName: "pages/banner/index" */))
const _2fcab91c = () => interopDefault(import('../pages/company/index.vue' /* webpackChunkName: "pages/company/index" */))
const _00b6b5b8 = () => interopDefault(import('../pages/cruise-map.vue' /* webpackChunkName: "pages/cruise-map" */))
const _051e0f4b = () => interopDefault(import('../pages/cruise-map/composables/useCompanyList.js' /* webpackChunkName: "pages/cruise-map/composables/useCompanyList" */))
const _15d0c3bf = () => interopDefault(import('../pages/designer.vue' /* webpackChunkName: "pages/designer" */))
const _64853f02 = () => interopDefault(import('../pages/designer/index.vue' /* webpackChunkName: "pages/designer/index" */))
const _5a3ee044 = () => interopDefault(import('../pages/designer/customer.vue' /* webpackChunkName: "pages/designer/customer" */))
const _75c584fa = () => interopDefault(import('../pages/designer/customization.vue' /* webpackChunkName: "pages/designer/customization" */))
const _caa1345a = () => interopDefault(import('../pages/designer/mine.vue' /* webpackChunkName: "pages/designer/mine" */))
const _1a087bc3 = () => interopDefault(import('../pages/designer/offiaccount.vue' /* webpackChunkName: "pages/designer/offiaccount" */))
const _dc8c3b44 = () => interopDefault(import('../pages/designer/tabbar.vue' /* webpackChunkName: "pages/designer/tabbar" */))
const _1071bd6d = () => interopDefault(import('../pages/destination/index.vue' /* webpackChunkName: "pages/destination/index" */))
const _2ec55b1c = () => interopDefault(import('../pages/feedback/index.vue' /* webpackChunkName: "pages/feedback/index" */))
const _24cbca63 = () => interopDefault(import('../pages/harbor/index.vue' /* webpackChunkName: "pages/harbor/index" */))
const _a19231ac = () => interopDefault(import('../pages/helpcenter.vue' /* webpackChunkName: "pages/helpcenter" */))
const _6fbfe62d = () => interopDefault(import('../pages/helpcenter/index.vue' /* webpackChunkName: "pages/helpcenter/index" */))
const _51d6f0d6 = () => interopDefault(import('../pages/helpcenter/help-info.vue' /* webpackChunkName: "pages/helpcenter/help-info" */))
const _8fd218c0 = () => interopDefault(import('../pages/helpcenter/question-answer.vue' /* webpackChunkName: "pages/helpcenter/question-answer" */))
const _9dea7b84 = () => interopDefault(import('../pages/introduce/index.vue' /* webpackChunkName: "pages/introduce/index" */))
const _12326e56 = () => interopDefault(import('../pages/merchant-center.vue' /* webpackChunkName: "pages/merchant-center" */))
const _081e34aa = () => interopDefault(import('../pages/order.vue' /* webpackChunkName: "pages/order" */))
const _1b51d8a6 = () => interopDefault(import('../pages/order/index.vue' /* webpackChunkName: "pages/order/index" */))
const _37f266b6 = () => interopDefault(import('../pages/order/confirmationsheet.vue' /* webpackChunkName: "pages/order/confirmationsheet" */))
const _2986abef = () => interopDefault(import('../pages/order/touristinformation.vue' /* webpackChunkName: "pages/order/touristinformation" */))
const _690833c8 = () => interopDefault(import('../pages/order/platform/cruiseship.vue' /* webpackChunkName: "pages/order/platform/cruiseship" */))
const _69bfde2a = () => interopDefault(import('../pages/order/platform/line.vue' /* webpackChunkName: "pages/order/platform/line" */))
const _f8469f18 = () => interopDefault(import('../pages/order/store/cruiseship.vue' /* webpackChunkName: "pages/order/store/cruiseship" */))
const _68eb02d2 = () => interopDefault(import('../pages/order/store/line.vue' /* webpackChunkName: "pages/order/store/line" */))
const _78eae762 = () => interopDefault(import('../pages/resource.vue' /* webpackChunkName: "pages/resource" */))
const _965e4736 = () => interopDefault(import('../pages/resource/index.vue' /* webpackChunkName: "pages/resource/index" */))
const _7f8abe80 = () => interopDefault(import('../pages/resource/invoice.vue' /* webpackChunkName: "pages/resource/invoice" */))
const _d22335fa = () => interopDefault(import('../pages/resource/invoice/index.vue' /* webpackChunkName: "pages/resource/invoice/index" */))
const _95d18c02 = () => interopDefault(import('../pages/resource/invoice/invoice-apply.vue' /* webpackChunkName: "pages/resource/invoice/invoice-apply" */))
const _a551a2e0 = () => interopDefault(import('../pages/resource/invoice/invoice-detail.vue' /* webpackChunkName: "pages/resource/invoice/invoice-detail" */))
const _8a6e5af0 = () => interopDefault(import('../pages/resource/invoice/invoice-payable-list.vue' /* webpackChunkName: "pages/resource/invoice/invoice-payable-list" */))
const _235a9da0 = () => interopDefault(import('../pages/resource/invoice/invoice-uninvoiced-order.vue' /* webpackChunkName: "pages/resource/invoice/invoice-uninvoiced-order" */))
const _5dd797e7 = () => interopDefault(import('../pages/resource/material/index.vue' /* webpackChunkName: "pages/resource/material/index" */))
const _0fde2445 = () => interopDefault(import('../pages/resource/product/index.vue' /* webpackChunkName: "pages/resource/product/index" */))
const _2449bd33 = () => interopDefault(import('../pages/resource/shop.vue' /* webpackChunkName: "pages/resource/shop" */))
const _be1b5d14 = () => interopDefault(import('../pages/resource/shop/index.vue' /* webpackChunkName: "pages/resource/shop/index" */))
const _6bb01a29 = () => interopDefault(import('../pages/resource/shop/agent.vue' /* webpackChunkName: "pages/resource/shop/agent" */))
const _533ec428 = () => interopDefault(import('../pages/resource/shop/agent/index.vue' /* webpackChunkName: "pages/resource/shop/agent/index" */))
const _1c25889e = () => interopDefault(import('../pages/resource/shop/agent/pending.vue' /* webpackChunkName: "pages/resource/shop/agent/pending" */))
const _16960a35 = () => interopDefault(import('../pages/resource/shop/income.vue' /* webpackChunkName: "pages/resource/shop/income" */))
const _546565d4 = () => interopDefault(import('../pages/resource/shop/productmanagement.vue' /* webpackChunkName: "pages/resource/shop/productmanagement" */))
const _518de3d4 = () => interopDefault(import('../pages/resource/shop/withdraw.vue' /* webpackChunkName: "pages/resource/shop/withdraw" */))
const _4fedc142 = () => interopDefault(import('../pages/resource/study/index.vue' /* webpackChunkName: "pages/resource/study/index" */))
const _6087f831 = () => interopDefault(import('../pages/resource/transaction.vue' /* webpackChunkName: "pages/resource/transaction" */))
const _eb48d128 = () => interopDefault(import('../pages/resource/transaction/list.vue' /* webpackChunkName: "pages/resource/transaction/list" */))
const _b82aa416 = () => interopDefault(import('../pages/resource/transaction/recharge.vue' /* webpackChunkName: "pages/resource/transaction/recharge" */))
const _7643bf83 = () => interopDefault(import('../pages/resource/transaction/withdrawal.vue' /* webpackChunkName: "pages/resource/transaction/withdrawal" */))
const _2de4066e = () => interopDefault(import('../pages/resource/messagecenter/order.vue' /* webpackChunkName: "pages/resource/messagecenter/order" */))
const _abd712fe = () => interopDefault(import('../pages/resource/messagecenter/store.vue' /* webpackChunkName: "pages/resource/messagecenter/store" */))
const _80f89058 = () => interopDefault(import('../pages/resource/official-accounts/left-config.vue' /* webpackChunkName: "pages/resource/official-accounts/left-config" */))
const _fa5bad40 = () => interopDefault(import('../pages/resource/official-accounts/messageEditor.vue' /* webpackChunkName: "pages/resource/official-accounts/messageEditor" */))
const _0d846666 = () => interopDefault(import('../pages/resource/official-accounts/officialaccountmenu.vue' /* webpackChunkName: "pages/resource/official-accounts/officialaccountmenu" */))
const _1722d237 = () => interopDefault(import('../pages/resource/official-accounts/right-config.vue' /* webpackChunkName: "pages/resource/official-accounts/right-config" */))
const _76d319de = () => interopDefault(import('../pages/resource/order/platform.vue' /* webpackChunkName: "pages/resource/order/platform" */))
const _60986733 = () => interopDefault(import('../pages/resource/order/store.vue' /* webpackChunkName: "pages/resource/order/store" */))
const _705bf959 = () => interopDefault(import('../pages/resource/product/collectionlist.vue' /* webpackChunkName: "pages/resource/product/collectionlist" */))
const _0ac5c834 = () => interopDefault(import('../pages/resource/tools/message.vue' /* webpackChunkName: "pages/resource/tools/message" */))
const _987ac42e = () => interopDefault(import('../pages/resource/tools/message/index.vue' /* webpackChunkName: "pages/resource/tools/message/index" */))
const _2744ca5c = () => interopDefault(import('../pages/resource/tools/message/create-temp.vue' /* webpackChunkName: "pages/resource/tools/message/create-temp" */))
const _3a19631b = () => interopDefault(import('../pages/resource/tools/message/log.vue' /* webpackChunkName: "pages/resource/tools/message/log" */))
const _f6bfff10 = () => interopDefault(import('../pages/resource/tools/message/masstexting.vue' /* webpackChunkName: "pages/resource/tools/message/masstexting" */))
const _53d9e5e0 = () => interopDefault(import('../pages/resource/tools/message/recharge.vue' /* webpackChunkName: "pages/resource/tools/message/recharge" */))
const _61699e7c = () => interopDefault(import('../pages/resource/tools/qrcode/index.vue' /* webpackChunkName: "pages/resource/tools/qrcode/index" */))
const _221fea0e = () => interopDefault(import('../pages/resource/usercenter/companyinfo.vue' /* webpackChunkName: "pages/resource/usercenter/companyinfo" */))
const _b4e85a70 = () => interopDefault(import('../pages/resource/usercenter/coupon.vue' /* webpackChunkName: "pages/resource/usercenter/coupon" */))
const _1dcce71e = () => interopDefault(import('../pages/resource/usercenter/staffmanagement.vue' /* webpackChunkName: "pages/resource/usercenter/staffmanagement" */))
const _23aa319b = () => interopDefault(import('../pages/resource/usercenter/userinfo.vue' /* webpackChunkName: "pages/resource/usercenter/userinfo" */))
const _193cb8f6 = () => interopDefault(import('../pages/resource/product/-list/cruise.vue' /* webpackChunkName: "pages/resource/product/-list/cruise" */))
const _41cf323c = () => interopDefault(import('../pages/resource/tools/qrcode/create.vue' /* webpackChunkName: "pages/resource/tools/qrcode/create" */))
const _b24c2a08 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _2accca18 = () => interopDefault(import('../pages/settled/index.vue' /* webpackChunkName: "pages/settled/index" */))
const _d82dc30e = () => interopDefault(import('../pages/ShopQrFlow.vue' /* webpackChunkName: "pages/ShopQrFlow" */))
const _5e2de420 = () => interopDefault(import('../pages/theme-cruise/index.vue' /* webpackChunkName: "pages/theme-cruise/index" */))
const _369ba20b = () => interopDefault(import('../pages/topic.vue' /* webpackChunkName: "pages/topic" */))
const _6581f6d0 = () => interopDefault(import('../pages/topic/ct-jsrcdj.vue' /* webpackChunkName: "pages/topic/ct-jsrcdj" */))
const _58dc7118 = () => interopDefault(import('../pages/topic/rebate-policy.vue' /* webpackChunkName: "pages/topic/rebate-policy" */))
const _4882ad0b = () => interopDefault(import('../pages/topic/shop-tool.vue' /* webpackChunkName: "pages/topic/shop-tool" */))
const _ee50f834 = () => interopDefault(import('../pages/topic/topic-temp.vue' /* webpackChunkName: "pages/topic/topic-temp" */))
const _de3ece0e = () => interopDefault(import('../pages/topic/2021/CenturyLegend-explorer.vue' /* webpackChunkName: "pages/topic/2021/CenturyLegend-explorer" */))
const _97d69c44 = () => interopDefault(import('../pages/topic/2021/yangzi-explorer.vue' /* webpackChunkName: "pages/topic/2021/yangzi-explorer" */))
const _6b86cb88 = () => interopDefault(import('../pages/topic/2022/aden/index.vue' /* webpackChunkName: "pages/topic/2022/aden/index" */))
const _8993781e = () => interopDefault(import('../pages/topic/202208/aden.vue' /* webpackChunkName: "pages/topic/202208/aden" */))
const _e0570c02 = () => interopDefault(import('../pages/topic/202208/hulunbuir.vue' /* webpackChunkName: "pages/topic/202208/hulunbuir" */))
const _29dde1b6 = () => interopDefault(import('../pages/topic/202208/secretlandscape.vue' /* webpackChunkName: "pages/topic/202208/secretlandscape" */))
const _6d2282ac = () => interopDefault(import('../pages/topic/2023/antarctica.vue' /* webpackChunkName: "pages/topic/2023/antarctica" */))
const _3fe7ff5c = () => interopDefault(import('../pages/topic/2023/blue-dream.vue' /* webpackChunkName: "pages/topic/2023/blue-dream" */))
const _14ad5868 = () => interopDefault(import('../pages/topic/2023/jsrcdj.vue' /* webpackChunkName: "pages/topic/2023/jsrcdj" */))
const _7319a5a6 = () => interopDefault(import('../pages/topic/2023/spectrum-of-the-seas.vue' /* webpackChunkName: "pages/topic/2023/spectrum-of-the-seas" */))
const _0d59b2c2 = () => interopDefault(import('../pages/user.vue' /* webpackChunkName: "pages/user" */))
const _30ff7951 = () => interopDefault(import('../pages/company/brand.vue' /* webpackChunkName: "pages/company/brand" */))
const _423a8daa = () => interopDefault(import('../pages/company/cruise-company.vue' /* webpackChunkName: "pages/company/cruise-company" */))
const _7f484b60 = () => interopDefault(import('../pages/company/cruise-list.vue' /* webpackChunkName: "pages/company/cruise-list" */))
const _a8233bcc = () => interopDefault(import('../pages/company/cruise-notice.vue' /* webpackChunkName: "pages/company/cruise-notice" */))
const _822a37fa = () => interopDefault(import('../pages/company/info-recommend.vue' /* webpackChunkName: "pages/company/info-recommend" */))
const _aabe0a14 = () => interopDefault(import('../pages/company/information.vue' /* webpackChunkName: "pages/company/information" */))
const _40c0a178 = () => interopDefault(import('../pages/company/list.vue' /* webpackChunkName: "pages/company/list" */))
const _47801078 = () => interopDefault(import('../pages/company/new-ranking-basis.vue' /* webpackChunkName: "pages/company/new-ranking-basis" */))
const _0bddc7aa = () => interopDefault(import('../pages/company/old-brand.vue' /* webpackChunkName: "pages/company/old-brand" */))
const _0bb54db6 = () => interopDefault(import('../pages/company/original-home.vue' /* webpackChunkName: "pages/company/original-home" */))
const _3341cace = () => interopDefault(import('../pages/company/trade-home.vue' /* webpackChunkName: "pages/company/trade-home" */))
const _258c0d0b = () => interopDefault(import('../pages/customer/list.vue' /* webpackChunkName: "pages/customer/list" */))
const _b663465a = () => interopDefault(import('../pages/destination/list.vue' /* webpackChunkName: "pages/destination/list" */))
const _9396d92c = () => interopDefault(import('../pages/harbor/city.vue' /* webpackChunkName: "pages/harbor/city" */))
const _920a0480 = () => interopDefault(import('../pages/harbor/product.vue' /* webpackChunkName: "pages/harbor/product" */))
const _fc22f71c = () => interopDefault(import('../pages/product/cruise-calendar.vue' /* webpackChunkName: "pages/product/cruise-calendar" */))
const _3d84f132 = () => interopDefault(import('../pages/product/custom-travel.vue' /* webpackChunkName: "pages/product/custom-travel" */))
const _050b00f4 = () => interopDefault(import('../pages/product/end-order-discount.vue' /* webpackChunkName: "pages/product/end-order-discount" */))
const _6fd51c60 = () => interopDefault(import('../pages/product/holiday-route.vue' /* webpackChunkName: "pages/product/holiday-route" */))
const _5cf59194 = () => interopDefault(import('../pages/product/vacation/index.vue' /* webpackChunkName: "pages/product/vacation/index" */))
const _b4951496 = () => interopDefault(import('../pages/settled/process.vue' /* webpackChunkName: "pages/settled/process" */))
const _7ffe8280 = () => interopDefault(import('../pages/company/cruise/century.vue' /* webpackChunkName: "pages/company/cruise/century" */))
const _0f2a71c1 = () => interopDefault(import('../pages/company/cruise/decknavigation.vue' /* webpackChunkName: "pages/company/cruise/decknavigation" */))
const _e7106784 = () => interopDefault(import('../pages/company/cruise/gallery.vue' /* webpackChunkName: "pages/company/cruise/gallery" */))
const _f737820e = () => interopDefault(import('../pages/company/cruise/location.vue' /* webpackChunkName: "pages/company/cruise/location" */))
const _e7ad457e = () => interopDefault(import('../pages/feedback/components/list.vue' /* webpackChunkName: "pages/feedback/components/list" */))
const _65dba731 = () => interopDefault(import('../pages/feedback/components/suggest.vue' /* webpackChunkName: "pages/feedback/components/suggest" */))
const _fb156d3a = () => interopDefault(import('../pages/product/automation/foreign-cruise-search.vue' /* webpackChunkName: "pages/product/automation/foreign-cruise-search" */))
const _0b4df65b = () => interopDefault(import('../pages/product/automation/placeorder.vue' /* webpackChunkName: "pages/product/automation/placeorder" */))
const _87bde6c4 = () => interopDefault(import('../pages/product/automation/placeorder/index.vue' /* webpackChunkName: "pages/product/automation/placeorder/index" */))
const _3482f05f = () => interopDefault(import('../pages/product/automation/placeorder/select-floor.vue' /* webpackChunkName: "pages/product/automation/placeorder/select-floor" */))
const _7a64f082 = () => interopDefault(import('../pages/product/automation/placeorder/select-room-type.vue' /* webpackChunkName: "pages/product/automation/placeorder/select-room-type" */))
const _36f2e734 = () => interopDefault(import('../pages/product/automation/placeorder/visitor-fill.vue' /* webpackChunkName: "pages/product/automation/placeorder/visitor-fill" */))
const _33129d82 = () => interopDefault(import('../pages/product/automation/requirements-agreements.vue' /* webpackChunkName: "pages/product/automation/requirements-agreements" */))
const _968d2fda = () => interopDefault(import('../pages/product/cruise/booking.vue' /* webpackChunkName: "pages/product/cruise/booking" */))
const _493c95d4 = () => interopDefault(import('../pages/product/cruise/list.vue' /* webpackChunkName: "pages/product/cruise/list" */))
const _55a9aee9 = () => interopDefault(import('../pages/product/cruise/pre-booking.vue' /* webpackChunkName: "pages/product/cruise/pre-booking" */))
const _7dfbe7cc = () => interopDefault(import('../pages/product/vacation/list.vue' /* webpackChunkName: "pages/product/vacation/list" */))
const _0e2fa396 = () => interopDefault(import('../pages/product/vacation/placeholder.vue' /* webpackChunkName: "pages/product/vacation/placeholder" */))
const _053e941e = () => interopDefault(import('../pages/product/vacation/pre-booking.vue' /* webpackChunkName: "pages/product/vacation/pre-booking" */))
const _215917a4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _f6ac0cde = () => interopDefault(import('../pages/company/article/_articleId.vue' /* webpackChunkName: "pages/company/article/_articleId" */))
const _7e4510ca = () => interopDefault(import('../pages/company/cruise/_cruiseId.vue' /* webpackChunkName: "pages/company/cruise/_cruiseId" */))
const _96a338b8 = () => interopDefault(import('../pages/partner/content/_index.vue' /* webpackChunkName: "pages/partner/content/_index" */))
const _4d84ef76 = () => interopDefault(import('../pages/product/automation/_productNo.vue' /* webpackChunkName: "pages/product/automation/_productNo" */))
const _ba8d5272 = () => interopDefault(import('../pages/product/cruise/_productNo.vue' /* webpackChunkName: "pages/product/cruise/_productNo" */))
const _24014ebf = () => interopDefault(import('../pages/product/vacation/_productNo.vue' /* webpackChunkName: "pages/product/vacation/_productNo" */))
const _156bb336 = () => interopDefault(import('../pages/approval/_approvalId.vue' /* webpackChunkName: "pages/approval/_approvalId" */))
const _39fb6b9f = () => interopDefault(import('../pages/company/_companyId.vue' /* webpackChunkName: "pages/company/_companyId" */))
const _4b1628e8 = () => interopDefault(import('../pages/cruise-ranking/_basis.vue' /* webpackChunkName: "pages/cruise-ranking/_basis" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/banner",
    component: _351d4903,
    name: "banner"
  }, {
    path: "/company",
    component: _2fcab91c,
    name: "company"
  }, {
    path: "/cruise-map",
    component: _00b6b5b8,
    name: "cruise-map",
    children: [{
      path: "composables/useCompanyList",
      component: _051e0f4b,
      name: "cruise-map-composables-useCompanyList"
    }]
  }, {
    path: "/designer",
    component: _15d0c3bf,
    children: [{
      path: "",
      component: _64853f02,
      name: "designer"
    }, {
      path: "customer",
      component: _5a3ee044,
      name: "designer-customer"
    }, {
      path: "customization",
      component: _75c584fa,
      name: "designer-customization"
    }, {
      path: "mine",
      component: _caa1345a,
      name: "designer-mine"
    }, {
      path: "offiaccount",
      component: _1a087bc3,
      name: "designer-offiaccount"
    }, {
      path: "tabbar",
      component: _dc8c3b44,
      name: "designer-tabbar"
    }]
  }, {
    path: "/destination",
    component: _1071bd6d,
    name: "destination"
  }, {
    path: "/feedback",
    component: _2ec55b1c,
    name: "feedback"
  }, {
    path: "/harbor",
    component: _24cbca63,
    name: "harbor"
  }, {
    path: "/helpcenter",
    component: _a19231ac,
    children: [{
      path: "",
      component: _6fbfe62d,
      name: "helpcenter"
    }, {
      path: "help-info",
      component: _51d6f0d6,
      name: "helpcenter-help-info"
    }, {
      path: "question-answer",
      component: _8fd218c0,
      name: "helpcenter-question-answer"
    }]
  }, {
    path: "/introduce",
    component: _9dea7b84,
    name: "introduce"
  }, {
    path: "/merchant-center",
    component: _12326e56,
    name: "merchant-center"
  }, {
    path: "/order",
    component: _081e34aa,
    children: [{
      path: "",
      component: _1b51d8a6,
      name: "order"
    }, {
      path: "confirmationsheet",
      component: _37f266b6,
      name: "order-confirmationsheet"
    }, {
      path: "touristinformation",
      component: _2986abef,
      name: "order-touristinformation"
    }, {
      path: "platform/cruiseship",
      component: _690833c8,
      name: "order-platform-cruiseship"
    }, {
      path: "platform/line",
      component: _69bfde2a,
      name: "order-platform-line"
    }, {
      path: "store/cruiseship",
      component: _f8469f18,
      name: "order-store-cruiseship"
    }, {
      path: "store/line",
      component: _68eb02d2,
      name: "order-store-line"
    }]
  }, {
    path: "/resource",
    component: _78eae762,
    children: [{
      path: "",
      component: _965e4736,
      name: "resource"
    }, {
      path: "invoice",
      component: _7f8abe80,
      children: [{
        path: "",
        component: _d22335fa,
        name: "resource-invoice"
      }, {
        path: "invoice-apply",
        component: _95d18c02,
        name: "resource-invoice-invoice-apply"
      }, {
        path: "invoice-detail",
        component: _a551a2e0,
        name: "resource-invoice-invoice-detail"
      }, {
        path: "invoice-payable-list",
        component: _8a6e5af0,
        name: "resource-invoice-invoice-payable-list"
      }, {
        path: "invoice-uninvoiced-order",
        component: _235a9da0,
        name: "resource-invoice-invoice-uninvoiced-order"
      }]
    }, {
      path: "material",
      component: _5dd797e7,
      name: "resource-material"
    }, {
      path: "product",
      component: _0fde2445,
      name: "resource-product"
    }, {
      path: "shop",
      component: _2449bd33,
      children: [{
        path: "",
        component: _be1b5d14,
        name: "resource-shop"
      }, {
        path: "agent",
        component: _6bb01a29,
        children: [{
          path: "",
          component: _533ec428,
          name: "resource-shop-agent"
        }, {
          path: "pending",
          component: _1c25889e,
          name: "resource-shop-agent-pending"
        }]
      }, {
        path: "income",
        component: _16960a35,
        name: "resource-shop-income"
      }, {
        path: "productmanagement",
        component: _546565d4,
        name: "resource-shop-productmanagement"
      }, {
        path: "withdraw",
        component: _518de3d4,
        name: "resource-shop-withdraw"
      }]
    }, {
      path: "study",
      component: _4fedc142,
      name: "resource-study"
    }, {
      path: "transaction",
      component: _6087f831,
      name: "resource-transaction",
      children: [{
        path: "list",
        component: _eb48d128,
        name: "resource-transaction-list"
      }, {
        path: "recharge",
        component: _b82aa416,
        name: "resource-transaction-recharge"
      }, {
        path: "withdrawal",
        component: _7643bf83,
        name: "resource-transaction-withdrawal"
      }]
    }, {
      path: "messagecenter/order",
      component: _2de4066e,
      name: "resource-messagecenter-order"
    }, {
      path: "messagecenter/store",
      component: _abd712fe,
      name: "resource-messagecenter-store"
    }, {
      path: "official-accounts/left-config",
      component: _80f89058,
      name: "resource-official-accounts-left-config"
    }, {
      path: "official-accounts/messageEditor",
      component: _fa5bad40,
      name: "resource-official-accounts-messageEditor"
    }, {
      path: "official-accounts/officialaccountmenu",
      component: _0d846666,
      name: "resource-official-accounts-officialaccountmenu"
    }, {
      path: "official-accounts/right-config",
      component: _1722d237,
      name: "resource-official-accounts-right-config"
    }, {
      path: "order/platform",
      component: _76d319de,
      name: "resource-order-platform"
    }, {
      path: "order/store",
      component: _60986733,
      name: "resource-order-store"
    }, {
      path: "product/collectionlist",
      component: _705bf959,
      name: "resource-product-collectionlist"
    }, {
      path: "tools/message",
      component: _0ac5c834,
      children: [{
        path: "",
        component: _987ac42e,
        name: "resource-tools-message"
      }, {
        path: "create-temp",
        component: _2744ca5c,
        name: "resource-tools-message-create-temp"
      }, {
        path: "log",
        component: _3a19631b,
        name: "resource-tools-message-log"
      }, {
        path: "masstexting",
        component: _f6bfff10,
        name: "resource-tools-message-masstexting"
      }, {
        path: "recharge",
        component: _53d9e5e0,
        name: "resource-tools-message-recharge"
      }]
    }, {
      path: "tools/qrcode",
      component: _61699e7c,
      name: "resource-tools-qrcode"
    }, {
      path: "usercenter/companyinfo",
      component: _221fea0e,
      name: "resource-usercenter-companyinfo"
    }, {
      path: "usercenter/coupon",
      component: _b4e85a70,
      name: "resource-usercenter-coupon"
    }, {
      path: "usercenter/staffmanagement",
      component: _1dcce71e,
      name: "resource-usercenter-staffmanagement"
    }, {
      path: "usercenter/userinfo",
      component: _23aa319b,
      name: "resource-usercenter-userinfo"
    }, {
      path: "product/-list/cruise",
      component: _193cb8f6,
      name: "resource-product--list-cruise"
    }, {
      path: "tools/qrcode/create",
      component: _41cf323c,
      name: "resource-tools-qrcode-create"
    }]
  }, {
    path: "/search",
    component: _b24c2a08,
    name: "search"
  }, {
    path: "/settled",
    component: _2accca18,
    name: "settled"
  }, {
    path: "/ShopQrFlow",
    component: _d82dc30e,
    name: "ShopQrFlow"
  }, {
    path: "/theme-cruise",
    component: _5e2de420,
    name: "theme-cruise"
  }, {
    path: "/topic",
    component: _369ba20b,
    name: "topic",
    children: [{
      path: "ct-jsrcdj",
      component: _6581f6d0,
      name: "topic-ct-jsrcdj"
    }, {
      path: "rebate-policy",
      component: _58dc7118,
      name: "topic-rebate-policy"
    }, {
      path: "shop-tool",
      component: _4882ad0b,
      name: "topic-shop-tool"
    }, {
      path: "topic-temp",
      component: _ee50f834,
      name: "topic-topic-temp"
    }, {
      path: "2021/CenturyLegend-explorer",
      component: _de3ece0e,
      name: "topic-2021-CenturyLegend-explorer"
    }, {
      path: "2021/yangzi-explorer",
      component: _97d69c44,
      name: "topic-2021-yangzi-explorer"
    }, {
      path: "2022/aden",
      component: _6b86cb88,
      name: "topic-2022-aden"
    }, {
      path: "202208/aden",
      component: _8993781e,
      name: "topic-202208-aden"
    }, {
      path: "202208/hulunbuir",
      component: _e0570c02,
      name: "topic-202208-hulunbuir"
    }, {
      path: "202208/secretlandscape",
      component: _29dde1b6,
      name: "topic-202208-secretlandscape"
    }, {
      path: "2023/antarctica",
      component: _6d2282ac,
      name: "topic-2023-antarctica"
    }, {
      path: "2023/blue-dream",
      component: _3fe7ff5c,
      name: "topic-2023-blue-dream"
    }, {
      path: "2023/jsrcdj",
      component: _14ad5868,
      name: "topic-2023-jsrcdj"
    }, {
      path: "2023/spectrum-of-the-seas",
      component: _7319a5a6,
      name: "topic-2023-spectrum-of-the-seas"
    }]
  }, {
    path: "/user",
    component: _0d59b2c2,
    name: "user"
  }, {
    path: "/company/brand",
    component: _30ff7951,
    name: "company-brand"
  }, {
    path: "/company/cruise-company",
    component: _423a8daa,
    name: "company-cruise-company"
  }, {
    path: "/company/cruise-list",
    component: _7f484b60,
    name: "company-cruise-list"
  }, {
    path: "/company/cruise-notice",
    component: _a8233bcc,
    name: "company-cruise-notice"
  }, {
    path: "/company/info-recommend",
    component: _822a37fa,
    name: "company-info-recommend"
  }, {
    path: "/company/information",
    component: _aabe0a14,
    name: "company-information"
  }, {
    path: "/company/list",
    component: _40c0a178,
    name: "company-list"
  }, {
    path: "/company/new-ranking-basis",
    component: _47801078,
    name: "company-new-ranking-basis"
  }, {
    path: "/company/old-brand",
    component: _0bddc7aa,
    name: "company-old-brand"
  }, {
    path: "/company/original-home",
    component: _0bb54db6,
    name: "company-original-home"
  }, {
    path: "/company/trade-home",
    component: _3341cace,
    name: "company-trade-home"
  }, {
    path: "/customer/list",
    component: _258c0d0b,
    name: "customer-list"
  }, {
    path: "/destination/list",
    component: _b663465a,
    name: "destination-list"
  }, {
    path: "/harbor/city",
    component: _9396d92c,
    name: "harbor-city"
  }, {
    path: "/harbor/product",
    component: _920a0480,
    name: "harbor-product"
  }, {
    path: "/product/cruise-calendar",
    component: _fc22f71c,
    name: "product-cruise-calendar"
  }, {
    path: "/product/custom-travel",
    component: _3d84f132,
    name: "product-custom-travel"
  }, {
    path: "/product/end-order-discount",
    component: _050b00f4,
    name: "product-end-order-discount"
  }, {
    path: "/product/holiday-route",
    component: _6fd51c60,
    name: "product-holiday-route"
  }, {
    path: "/product/vacation",
    component: _5cf59194,
    name: "product-vacation"
  }, {
    path: "/settled/process",
    component: _b4951496,
    name: "settled-process"
  }, {
    path: "/company/cruise/century",
    component: _7ffe8280,
    name: "company-cruise-century"
  }, {
    path: "/company/cruise/decknavigation",
    component: _0f2a71c1,
    name: "company-cruise-decknavigation"
  }, {
    path: "/company/cruise/gallery",
    component: _e7106784,
    name: "company-cruise-gallery"
  }, {
    path: "/company/cruise/location",
    component: _f737820e,
    name: "company-cruise-location"
  }, {
    path: "/feedback/components/list",
    component: _e7ad457e,
    name: "feedback-components-list"
  }, {
    path: "/feedback/components/suggest",
    component: _65dba731,
    name: "feedback-components-suggest"
  }, {
    path: "/product/automation/foreign-cruise-search",
    component: _fb156d3a,
    name: "product-automation-foreign-cruise-search"
  }, {
    path: "/product/automation/placeorder",
    component: _0b4df65b,
    children: [{
      path: "",
      component: _87bde6c4,
      name: "product-automation-placeorder"
    }, {
      path: "select-floor",
      component: _3482f05f,
      name: "product-automation-placeorder-select-floor"
    }, {
      path: "select-room-type",
      component: _7a64f082,
      name: "product-automation-placeorder-select-room-type"
    }, {
      path: "visitor-fill",
      component: _36f2e734,
      name: "product-automation-placeorder-visitor-fill"
    }]
  }, {
    path: "/product/automation/requirements-agreements",
    component: _33129d82,
    name: "product-automation-requirements-agreements"
  }, {
    path: "/product/cruise/booking",
    component: _968d2fda,
    name: "product-cruise-booking"
  }, {
    path: "/product/cruise/list",
    component: _493c95d4,
    name: "product-cruise-list"
  }, {
    path: "/product/cruise/pre-booking",
    component: _55a9aee9,
    name: "product-cruise-pre-booking"
  }, {
    path: "/product/vacation/list",
    component: _7dfbe7cc,
    name: "product-vacation-list"
  }, {
    path: "/product/vacation/placeholder",
    component: _0e2fa396,
    name: "product-vacation-placeholder"
  }, {
    path: "/product/vacation/pre-booking",
    component: _053e941e,
    name: "product-vacation-pre-booking"
  }, {
    path: "/",
    component: _215917a4,
    name: "index"
  }, {
    path: "/company/article/:articleId?",
    component: _f6ac0cde,
    name: "company-article-articleId"
  }, {
    path: "/company/cruise/:cruiseId?",
    component: _7e4510ca,
    name: "company-cruise-cruiseId"
  }, {
    path: "/partner/content/:index",
    component: _96a338b8,
    name: "partner-content"
  }, {
    path: "/product/automation/:productNo?",
    component: _4d84ef76,
    name: "product-automation-productNo"
  }, {
    path: "/product/cruise/:productNo?",
    component: _ba8d5272,
    name: "product-cruise-productNo"
  }, {
    path: "/product/vacation/:productNo",
    component: _24014ebf,
    name: "product-vacation-productNo"
  }, {
    path: "/approval/:approvalId?",
    component: _156bb336,
    name: "approval-approvalId"
  }, {
    path: "/company/:companyId",
    component: _39fb6b9f,
    name: "company-companyId"
  }, {
    path: "/cruise-ranking/:basis?",
    component: _4b1628e8,
    name: "cruise-ranking-basis"
  }, {
    path: "*",
    redirect: "/"
  }, {
    path: "/company/rank/:rankingBasis?",
    redirect: (to) => {
            const obj = {
              service: 'service_ratio',
              tonnage: 'tonnage',
              capacity: 'passenger_capacity',
              newest: 'first_flight_time'
            };
            return `/cruise-ranking/${obj[to.params.rankingBasis] || ''}`;
          },
    name: "company-rank-rankingBasis"
  }],

  parseQuery: function(...rest) {
  return require("qs").parse(...rest);
},
  stringifyQuery: function(query) {
  return require("qs").stringify(query, { addQueryPrefix: true });
},
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
